import React from 'react';
import drewRobertRipImage from './drew-robert-rip.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={drewRobertRipImage} className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
